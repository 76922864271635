import React, { useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Contacts = () => {
  const [validated, setValidated] = useState(false)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const [loading, setLoading] = useState(false)

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    pcolor: "red",
  })

  const handleServerResponse = (ok, msg, form) => {
    setLoading(false)

    console.log(ok)
    if (!ok) {
      setServerState({
        submitting: false,
        status: { ok, msg },
        pcolor: "red",
      })
    }
    if (ok) {
      setServerState({
        submitting: true,
        status: { ok, msg },
        pcolor: "darkgreen",
      })
    }
  }

  const handleSubmit = evt => {
    const form = evt.currentTarget
    if (form.checkValidity() === false) {
      evt.preventDefault()
      evt.stopPropagation()

      setValidated(true)
    } else {
      setLoading(true)

      evt.preventDefault()
      var xhr = new XMLHttpRequest()
      var url =
        "https://api.hsforms.com/submissions/v3/integration/submit/6592433/e861e867-e37e-4b2f-9cb5-96305e3924cf"
      var data = {
        fields: [
          {
            name: "email",
            value: email,
          },
          {
            name: "firstname",
            value: name,
          },
          {
            name: "message",
            value: message,
          },
        ],
        context: {
          pageUri: "www.azszero.com",
          pageName: "azszero-C",
        },
      }
      //   console.log(data)
      var final_data = JSON.stringify(data)

      xhr.open("POST", url)
      // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
      xhr.setRequestHeader("Content-Type", "application/json")

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          handleServerResponse(true, "Submitted Successfully!", form)
        } else {
          handleServerResponse(
            false,
            "The Form was not submitted, Please try again.",
            form
          )
        }
      }
      // Sends the request
      xhr.send(final_data)
    }
  }

  return (
    <Layout>
      <Seo
        title="Contact AZSzero"
        description="Contact us let us know if you have any questions, need any help or have a great suggestion."
      />

      <Container fluid className="px-0">
        <div className="text-center" style={{ position: "relative" }}>
          <Styledh1 className="display-4">Contac Us</Styledh1>
          <StaticImage
            src="../assets/images/abou-bg.jpg"
            alt="Amazon FBA App Features"
            placeholder="tracedSVG"
            layout="fullWidth"
            aspectRatio={6 / 1}
            style={{ zIndex: "-1" }}
          ></StaticImage>
        </div>
        <Container className="p-5">
          <Row className="mx-auto">
            <Col>
              <h4>Having a problem and need some help?</h4>
              <h4>Need more clarification on a subject?</h4>
              <h4>Have a suggestion to add to the App?</h4>
            </Col>
          </Row>
          <Row className="mx-auto">
            <Col sm={12} md={6} lg={4} className="pb-5"></Col>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} sm="12" md="6" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="John Smith"
                    defaultValue=""
                    //              value={name}

                    onChange={e => setName(e.target.value)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="12" md="6" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="me@domain.com"
                    defaultValue=""
                    //   value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Form.Group controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                  //value={message}
                  onChange={e => setMessage(e.target.value)}
                />
              </Form.Group>

              {/* <Button type="submit" disabled={serverState.submitting}> */}
              <Button
                type="submit"
                style={{ marginTop: "10px" }}
                disabled={loading}
              >
                {loading ? "Wait..." : "Submit"}
              </Button>
              <Row className="mx-auto text-center">
                <Col>
                  {serverState.status && (
                    <StyledP
                      className={!serverState.status.ok ? "errorMsg" : ""}
                      style={{ color: serverState.pcolor }}
                    >
                      {serverState.status.msg}
                    </StyledP>
                  )}
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}

const Styledh1 = styled.h1`
  padding: 30px;
  color: slategray;
  margin-bottom: -50px;
  z-index: 10;
  font-weight: 700;
`

const StyledP = styled.p`
  color: darkgreen;
  font-weight: bold;
  font-size: "large";
`

export default Contacts

//   {
//     /* <form onSubmit={handleSubmit}>
//     <label>
//       Full Name:
//       <input
//         type="text"
//         value={name}
//         onChange={e => setName(e.target.value)}
//       />
//     </label>
//     <label>
//       Email
//       <input
//         type="text"
//         value={email}
//         onChange={e => setEmail(e.target.value)}
//       />
//     </label>
//     <label>
//       Email
//       <input
//         type="text"
//         value={message}
//         onChange={e => setMessage(e.target.value)}
//       />
//     </label>
//     <input type="submit" value="Submit" />
//   </form> */
//   }

// <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
// <script>
//   hbspt.forms.create({
// 	region: "na1",
// 	portalId: "6592433",
// 	formId: "e861e867-e37e-4b2f-9cb5-96305e3924cf"
// });
// </script>

//   {
//       "id": "e861e867-e37e-4b2f-9cb5-96305e3924cf",
//       "name": "New contact us form (February 16, 2020 5:07:36 AM) ",
//       "createdAt": "2020-02-15T21:07:38.346Z",
//       "updatedAt": "2021-09-01T16:56:49.321Z",
//       "archived": false,
//       "fieldGroups": [
//         {
//           "groupType": "default_group",
//           "richTextType": "text",
//           "fields": [
//             {
//               "objectTypeId": "0-1",
//               "name": "email",
//               "label": "Email",
//               "required": true,
//               "hidden": false,
//               "fieldType": "email",
//               "validation": {
//                 "blockedEmailDomains": [],
//                 "useDefaultBlockList": false
//               }
//             }
//           ]
//         },
//         {
//           "groupType": "default_group",
//           "richTextType": "text",
//           "fields": [
//             {
//               "objectTypeId": "0-1",
//               "name": "firstname",
//               "label": "First name",
//               "required": false,
//               "hidden": false,
//               "fieldType": "single_line_text"
//             }
//           ]
//         },
//         {
//           "groupType": "default_group",
//           "richTextType": "text",
//           "fields": [
//             {
//               "objectTypeId": "0-1",
//               "name": "message",
//               "label": "Message",
//               "required": false,
//               "hidden": false,
//               "fieldType": "multi_line_text"
//             }
//           ]
//         }
//       ],
//       "configuration": {
//         "language": "en",
//         "cloneable": true,
//         "postSubmitAction": {
//           "type": "thank_you",
//           "value": "Thanks for submitting the form."
//         },
//         "editable": true,
//         "archivable": true,
//         "recaptchaEnabled": false,
//         "notifyContactOwner": false,
//         "notifyRecipients": [
//           "9107109"
//         ],
//         "createNewContactForNewEmail": false,
//         "prePopulateKnownValues": true,
//         "allowLinkToResetKnownValues": false
//       },
//       "displayOptions": {
//         "renderRawHtml": false,
//         "theme": "default_style",
//         "submitButtonText": "Submit",
//         "style": {
//           "fontFamily": "arial, helvetica, sans-serif",
//           "backgroundWidth": "100%",
//           "labelTextColor": "#33475b",
//           "labelTextSize": "13px",
//           "helpTextColor": "#7C98B6",
//           "helpTextSize": "11px",
//           "legalConsentTextColor": "#33475b",
//           "legalConsentTextSize": "14px",
//           "submitColor": "#ff7a59",
//           "submitAlignment": "left",
//           "submitFontColor": "#ffffff",
//           "submitSize": "12px"
//         },
//         "cssClass": "hs-form stacked"
//       },
//       "legalConsentOptions": {
//         "type": "none"
//       },
//       "formType": "hubspot"
//     }
